*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;

    cursor: default;
}

input {
    outline: none;
}

body {
    background: #FCFCFC;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    max-width: 1920px;
}

section {
    padding-left: 32px;
    padding-right: 32px;
}

a {
    text-decoration: none;
}

.logo {
    margin-right: 32px;
}

.container {
    max-width: 1500px;
    padding-left: 48px;
    padding-right: 48px;
}

.cart-detail__order-summary {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 23px;
    
    color: #333;
}

.main-baner-page {
    width: 100%;
    height: calc(100vh - 200px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    display: flex;
    align-items: center;
    position: relative;
}

.main-baner-page::before {
    position: absolute;
    content: "";
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url('assets/images/bg-header.png');
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    z-index: -1;
}

.baner__main-container {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    margin-top: 4rem;
    padding: 0 2rem;
}

.baner-header-text {
    font-family: 'Alumni Sans';
    font-size: 3.5rem;
    text-align: right;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.baner-header-description {
    max-width: 893px;
    margin-left: auto;
    margin-top: 1.5rem;
    font-weight: 400;
    text-align: right;
    font-family: 'Alumni Sans';
    font-size: 2rem;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.baner-header-button {
    display: block;
    margin-left: auto;
    font-family: 'Alumni Sans';
    font-size: 2rem;
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    padding: .5rem 2rem;
    margin-top: 1rem;
    cursor: pointer;
    transition: .3s ease;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.baner-header-button:hover {
    background: #FFFFFF;
    color: #333333;
}

.header__footer-block {
    width: 100%;
    height: auto;
    padding: 38px;
    background: #F5E5C7;
}

.h1__main {
    max-width: 803px;
    width: 100%;
    
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 32px;
    text-align: center;
    margin: 0px auto;
    
    color: #333333;
}

.header__footer-mini {
    display: flex;
    justify-content: center;
    gap: 96px;
    margin-top: 38px;
}

.header__footer-mini-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header__footer-title {
    margin-top: 12px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    
    color: #333333;
}

.header__footer-description {
    max-width: 225px;
    width: 100%;
    text-align: center;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin-top: 8px;
    color: #333333;
    text-transform: uppercase;
}

.section__block {
    max-width: 1280px;
    margin: 100px auto;
    width: 100%;
}

.section__block.flex {
    display: flex;
    gap: 32px;
    align-items: center;
}

.illustration__image {
    border-radius: 12px;
}

.section__info-text-title {
    margin-bottom: 28px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 24px;
    
    color: #333333;
}

.section__info-text-description {
    margin-top: 16px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.03em;
    
    color: #333333;
}

.section__info-text-description > span {
    font-weight: 600;
}

.section__info-text-title-mini {
    margin-top: 38px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    letter-spacing: 0.03em;
    
    color: #333333;
}

.section__title {
    width: 100%;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 34px;
    text-align: center;
    
    color: #333333;
}

.section__description {
    width: 100%;
    margin-top: 12px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    
    color: #333333;
}

.honors__block {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 48px;
    justify-content: space-around;
}

.faq-accordion__blocks {
    margin: 28px;
}

.container__product {
    max-width: 1020px;
    width: 100%;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
}

.product__path {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    color: #333333;
}

.product__main-block {
    display: flex;
    gap: 28px;
    justify-content: space-between;
    margin-top: 28px;
}

.product__min-images-block {
    display: flex;
    gap: 12px;
    margin-top: 12px;
}

.product__mini-image {
    width: 100px;
    border-radius: 8px;
}

.product__main-description-block {
    max-width: 500px;
    width: 100%;
}

.product__name {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 34px;
    
    color: #DE5F4B;
}

.product__price {
    margin-top: 32px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 32px;
    
    color: #333333;
}

.product__button-add-to-cart {
    max-width: 203px;
    width: 100%;
    display: block;
    margin-top: 28px;
    padding: 14px;
    background: #DE5F4B;
    border-radius: 5px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    transition: .3s ease;
}

.product__description {
    margin-top: 28px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 27px;
    padding-bottom: 28px;
    border-bottom: 1px solid #DE5F4B;

    color: #333333;
}

.product__detail-child-block {
    margin-top: 28px;
    display: flex;
    gap:32px;
}

.product__detail-child-title {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    color: #BE4330;
    white-space: nowrap;
}

.product__detail-child-description {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;

    color: #333333;
}

.product__detail-child-description > p {
    margin-bottom: 8px;
}

.product__selector-block {
    margin-top: 100px;
}

.container__catigory {
    margin-top: 32px;
    max-width: 1250px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.catigory__name {
    margin-top: 38px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 34px;
    text-align: center;

    color: #DE5F4B;
}

.catigory__description {
    margin-top: 12px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    text-align: center;
    line-height: 27px;

    color: #333333;
}

.catigory__products-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-top: 48px;
    margin-bottom: 120px;
}

.header__checkout-page {
    width: 100%;
    height: 69px;
    background: #BE4330;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__checkout-container {
    max-width: 1120px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: 32px;
}

.header__checkout-name-company {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    
    color: #FFFFFF;
}

.section__selector-payment-page {
    display: flex;
    width: 100%;
}

.section__payment-detail-block {
    width: 100%;
}

.section__cart-detail-blcok {
    background: #FAFAFA;
    max-width: 890px;
    width: 100%;
    flex-grow: 1;
    border: 1px solid #DFDFDF;
}

.payment-detail__block {
    max-width: 660px;
    width: 100%;
    margin-left: auto;
    padding: 38px;
}

.payment-detail__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    
    color: #000000;
}

.payment-detail__description {
    margin-top: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    
    color: rgba(0, 0, 0, 0.56);
}

.payment-detail__inputs-block {
    margin-top: 16px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.payment-detail__form-paymnent {
    margin-top: 15px;
}

.payment-detail__inputs-textarea {
    width: 100%;
    height: 100px;
    resize: none;
    outline: none;

    border: 1px solid #DEDEDE;
    border-radius: 5px;
    cursor: text;
    padding: 11px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    
    color: #000000;
}

.payment-detail__inputs-textarea:focus {
    border-color: #1990C6;
}

.payment-detail__form-header {
    padding: 16px 17px;
    background: #F0F7FF;
    border-top: 1px solid #1990C6;
    border-left: 1px solid #1990C6;
    border-right: 1px solid #1990C6;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-detail__header-icon-block {
    display: flex;
    gap: 5px;
}

.payment-detail__header-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    
    color: #000000;
}

.payment-detail__form-body {
    width: 100%;
    padding: 18px;
    background: rgba(0, 0, 0, 0.043);
    border: 1px solid #DFDFDF;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.input-payment {
    width: 100%;
    height: 50px;
    padding: 11px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    cursor: text;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #000000;
}

.input-payment:focus {
    border-color: #1990C6;
}

.input-payment.card {
    background-image: url('../src/assets/icon/lock-input-icon.svg');
    background-repeat: no-repeat;
    background-position: center right 14px;
    padding-right: 38px;
}

.input-payment.secur {
    background-image: url('../src/assets/icon/more_info-icon.svg');
    background-repeat: no-repeat;
    background-position: center right 14px;
    padding-right: 38px;
}

.payment-detail__mini-block {
    display: flex;
    align-items: center;
    gap: 14px;
}

.payment-detail__button-pay {
    margin-top: 48px;
    width: 100%;
    padding: 15px;
    background: #1990C6;
    border-radius: 5px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    
    color: #FFFFFF;
    cursor: pointer;
    transition: .2s ease;
}

.payment-detail__button-pay:hover {
    background: #1D7FAB;
}

.cart-detail__block {
    max-width: 520px;
    width: 100%;
    padding: 38px;
}

.cart-detail__itmes-cart-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 32px;
    border-bottom: 1px solid #E6E6E6;
}

.payment__info-main-block {
    margin-top: 16px;
}

.payment__info-block-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.payment__info-block-item.total {
    margin-top: 12px;
}

.payment__info-block-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    color: #000000;
}

.payment__info-block-title.total {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    
    color: #000000;
}

.payment__info-block-descript {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    color: #000000;
}

.payment__info-block-descript.total {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    
    color: #000000;
}

.payment__totoal-block-price {
    display: flex;
    align-items: end;
    gap: 7px;
}

.payment__currency {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    
    color: rgba(0, 0, 0, 0.56);
}

.payment__estimated-block {
    display: flex;
    align-items: center;
    gap: 6px;
}

@media (max-width: 1280px) {
    .logo {
        width: 48px;
    }
}

@media (max-width: 1200px) {
    .honors__block {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

@media (max-width: 1030px) {
    .logo {
        margin-right: 0px;
    }

    .section__block.flex {
        flex-direction: column;
    }

    .illustration__image {
        width: 100%;
    }

    .product__main-block {
        display: flex;
        flex-direction: column;
        gap: 28px;
        align-items: center;
        margin-top: 28px;
    }

    .product__min-images-block {
        display: none;
    }

    .main-baner-page::before {
        filter: blur(2.3px);
    }

    .baner-header-description {
        max-width: 590px;
    }
}

@media (max-width: 1060px) {
    .section__selector-payment-page {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
    }

    .cart-detail__block {
        margin-left: auto;
        margin-right: auto;
    }

    .payment-detail__block {
        margin-right: auto;
    }
}

@media (max-width: 800px) {
    .header__footer-mini {
        gap: 32px;
    }

    .main-baner-page::before {
        filter: blur(5px);
    }
}

@media (max-width: 595px) {
    .header__footer-mini {
        flex-wrap: wrap;
    }

    .baner-header-text {
        font-size: 2rem;
    }

    .baner-header-description {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .baner-header-button {
        font-size: 1.5rem;
    }
}

@media (max-width: 543px) {
    .baner__main-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    section {
        padding-left: 15px;
        padding-right: 15px;
    }

    .payment-detail__mini-block {
        flex-direction: column;
    }

    .payment-detail__block {
        padding-left: 25px;
        padding-right: 25px;
    }

    .cart-detail__block {
        padding-left: 25px;
        padding-right: 25px;
    }
}
