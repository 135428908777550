.photo-selector__main-block {
    width: 100%;
    position: relative;
    max-width: 490px;
}

.photo-selector__main-image {
    width: 100%;
    border-radius: 12px;
}

.photo-seelctor__button {
    position: absolute;
    width: 100px;
    height: 204px;
    cursor: pointer;
    background: transparent;
}

.photo-seelctor__button.prev {
    top: 50%;
    transform: translate(0px, 50%);
    background-image: url('../../assets/icon/photo-selector-icon.svg');
    rotate: 180deg;
    opacity: 0.5;
    transition: .5s ease;
}

.photo-seelctor__button.next {
    top: 50%;
    right: 0px;
    transform: translate(0px, -50%);
    background-image: url('../../assets/icon/photo-selector-icon.svg');
    opacity: 0.5;
    transition: .5s ease;
}

.photo-seelctor__button.prev:hover {
    opacity: 1;
}

.photo-seelctor__button.next:hover {
    opacity: 1;
}

@media (max-width: 800px) {
    .photo-seelctor__button.prev {
        opacity: 0.5;
    }

    .photo-seelctor__button.next {
        opacity: 0.5;
    }
}