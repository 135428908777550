.shopify-section__card-block {
    max-width: 301px;
    width: 100%;
    overflow: hidden;
    background: #F5E5C7;
    border-radius: 12px;
    position: relative;
    animation: showBlock 1.8s, ease-out;
    transition: .3s ease;
}

.shopify-section__card-block:hover {
    transform: scale(1.017);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

@keyframes showBlock {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.shopify-section__card-block-footer {
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 10px;
    padding-bottom: 12px;
}

.shopify-section__card-block-footer-top {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.shopify-section__card-name {
    max-width: 171px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    
    color: #333333;
}

.shopify-section__card-incltax {
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    
    color: #636363;
}

.shopify-section__card-price {
    margin-top: 8px;
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    
    color: #484848;
}

.shopify-section__card-button {
    position: absolute;
    width: 157px;
    height: 38px;
    
    bottom: 0px;
    right: 0px;
    background: #be4330;
    border-radius: 30px 0px 0px 0px;
    cursor: pointer;
    
    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    
    color: #FFFFFF;
    transition: .3s ease;
}

.shopify-section__card-button:hover {
    background: #d8351c;
}

.shopify-section__card-block-img {
    width: 100%;
    max-height: 377px;
    object-fit: cover;
    cursor: pointer;
}